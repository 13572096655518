.container {
    width: 100%;
    height: 100%;

    border: none !important;
    padding-block-start: .1rem;
    padding-block-end: .1rem;
}



.input{

    height: 100%;
    border: none !important;


}