:root {
  --body_background: #364273;
  --body_color: white;
  --link_color: white;
  --nav_link: white;
  --container: white;
  --container_color: #333;
  --active: white;
  --active_text: #364273;
  --gray: #7f7e7e;
  --light_gray: #19191a;
  --tab: green;
  --center: #f7f7f7;
  --auth: #f4f5f7;
  --auth_body: #fff;
  --modal: white;
  --backdrop: rgba(0, 0, 0, 0.25);
  --side: #364273;
  --brand: #364273;
}

[data-theme="black"] {
  --body_background: white;
  --body_color: #364273;
  --link_color: #364273;
  --nav_link: #fff;
  /* --container: #364273; */
  --container: #222;
  --container_color: white;
  --active: #222;
  --active_text: white;
  --gray: #ddd;
  --light_gray: #fff;
  --tab: "blue";
  --center: #222;
  --auth: #222;
  --auth_body: #333;
  --modal: #444;
  --backdrop: rgba(0, 0, 0, 0.45);
  --side: #666;
  --brand: rgb(43, 162, 218);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
